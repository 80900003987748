<template>
  <div>
    <b-form-group
      label="Work radius from office location"
      label-cols-sm="6"
      label-cols-lg="6"
      content-cols-sm
      content-cols-lg="6"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.workRadius"
        :options="workRadiusOptions"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group label="Number of Employees" class="gfb-radio-container">
      <b-form-radio-group
        v-model="fields.numberOfEmployees"
        :options="numberOfEmployeesOptions"
      ></b-form-radio-group> </b-form-group
    ><b-form-group
      label="Annual volume of completed projects"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.volumeCompletedProjects"
        :options="volumeCompletedProjectsOptions"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      label="What is the approximate time frame in which your company processes payments to subcontractors, vendors, and other payables?"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.provider.paymentProcessTime"
        :options="paymentProcessTimeOptions"
        text-field="name"
        value-field="id"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      label="Does your company utilize union labor on your projects?"
      label-cols-sm="6"
      label-cols-lg="6"
      content-cols-sm
      content-cols-lg="6"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.provider.utilizeUnionLabor"
        :options="trueFalse"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      label="Select one or more designations your business qualifies for"
      class="gfb-radio-container"
    >
      <b-form-checkbox-group
        class="gfb-radio-container"
        id="designation-options"
        v-model="fields.qualifiedDesignations"
        :options="designationsOptions"
      ></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
import options from "@/utils/options"
export default {
  name: "company-profile-edit-overview",
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      trueFalse: options.trueFalse,
      yesNo: options.yesNo,
      categoryOptions: options.categoryOptions,
      industryOptions: options.industryOptions,
      workRadiusOptions: options.workRadiusOptions,
      numberOfEmployeesOptions: options.numberOfEmployeesOptions,
      paymentProcessTimeOptions: options.paymentProcessTimeOptions,
      designationsOptions: options.designationsOptions,
      volumeCompletedProjectsOptions: options.volumeCompletedProjectsOptions,
    }
  },
}
</script>

<style lang="scss" scoped></style>
