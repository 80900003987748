<template>
  <div>
    <div>
      <h3>Upload a photo for your business profile (optional)</h3>
      <small class="d-block"
        >Format 500x500 pixels, JPEG / PNG, max 10 MB</small
      >
      <div v-if="profilePhoto" class="photo-container p-3 m-3">
        <div class="photo">
          <signed-image :file="profilePhoto" alt="" class="w-100" />
        </div>
        <b-button
          @click="profilePhoto = null"
          pill
          variant="light"
          class="avatar-remove-button"
        >
          <i class="fa fa-times fa-lg" />
        </b-button>
      </div>
      <b-form-file
        class="mb-3"
        v-if="!profilePhoto"
        v-model="profileUpload"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        accept="image/jpeg, image/png"
      ></b-form-file>
      <form-error v-show="errors.profilePhotoUpload">{{
        errors.profilePhotoUpload
      }}</form-error>
    </div>

    <div class="form-group">
      <h3>Upload photos of your best work for showcasing</h3>
      <small class="d-block">JPEG / PNG, max 10 MB</small>

      <b-container v-show="showcasePhotos.length > 0" class="my-3">
        <b-row>
          <b-col cols="6" v-for="photo in showcasePhotos" :key="photo._id">
            <div class="photo-container p-3">
              <div class="photo">
                <signed-image :file="photo" alt="" class="w-100" />
              </div>
              <b-button
                @click="
                  showcasePhotos = showcasePhotos.filter((url) => url !== photo)
                "
                pill
                variant="light"
                class="avatar-remove-button"
              >
                <i class="fa fa-times fa-lg" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-form-file
        class="mb-3"
        v-on:input="onShowCasePhotoUpload"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        accept="image/jpeg, image/png"
        multiple
        :file-name-formatter="formatFileNames"
      ></b-form-file>
      <form-error v-show="errors.showcasePhotosUpload">{{
        errors.showcasePhotosUpload
      }}</form-error>
    </div>

    <text-input
      label="How did you hear about us?"
      v-model="fields.howDidYouHear"
      :hasError="errors.howDidYouHear"
      :errMsg="errors.howDidYouHear"
    />
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus"
import formatFileNamesMixin from "@/mixins/formatFileNamesMixin"
import validatePhotosMixin from "@/mixins/validatePhotosMixin"
import { deNullifyErrors } from "@/utils/validations"
export default {
  name: "company-profile-photos",
  mixins: [formatFileNamesMixin, validatePhotosMixin],
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      profilePhoto: this.fields.profilePhoto,
      showcasePhotos: this.fields.showcasePhotos ?? [],
      profileUpload: null,
      showcaseUpload: [],
    }
  },
  mounted() {
    EventBus.$on("validate-company-photos", () => {
      this.validate()
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("validate-company-photos")
  },
  methods: {
    async validate() {
      let errors = {
        profilePhotoUpload: this.profileUpload
          ? await this.validatePhotos([this.profilePhotoUpload])
          : null,
        showcasePhotosUpload: await this.validatePhotos(
          this.showcaseUpload,
          undefined,
          false
        ),
      }
      this.errors = errors
      this.$emit(
        "change-photo-uploads",
        this.profileUpload,
        this.showcaseUpload,
        this.profilePhoto,
        this.showcasePhotos
      )
      this.$emit(
        "proceed-next",
        Object.keys(deNullifyErrors(errors)).length > 0 ? false : true
      )
    },
    onShowCasePhotoUpload(event) {
      this.showcaseUpload.push(...event)
    },
  },
}
</script>

<style lang="scss" scoped>
.photo-container {
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  width: 200px;
  height: 200px;
}

.photo {
  border-radius: 22px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  & img {
    border-radius: 22px;
    object-fit: cover;
  }
}
</style>
