<template>
  <div>
    <div>
      <h3 class="profile-form-label">
        Please list all prior jobs you have had starting with current job or
        most recent (up to 3)
      </h3>

      <b-row
        v-for="(_, index) in fields.jobSeeker.previousCompaniesWorkedFor"
        :key="index"
      >
        <b-col>
          <div class="employer-header">
            <h3 class="profile-form-label">Employer {{ index + 1 }}</h3>
            <i
              class="fa fa-trash"
              @click="
                fields.jobSeeker.previousCompaniesWorkedFor =
                  fields.jobSeeker.previousCompaniesWorkedFor.filter(
                    (_, i) => i !== index
                  )
              "
            ></i>
          </div>

          <text-input
            label="Your Position *"
            v-model="
              fields.jobSeeker.previousCompaniesWorkedFor[index].position
            "
            :hasError="
              errors.previousCompaniesWorkedFor &&
              errors.previousCompaniesWorkedFor[index] &&
              errors.previousCompaniesWorkedFor[index].position
            "
            :errMsg="
              errors.previousCompaniesWorkedFor &&
              errors.previousCompaniesWorkedFor[index]
                ? errors.previousCompaniesWorkedFor[index].position
                : ''
            "
          />

          <label>Duration</label>
          <yearly-date-picker
            placeholder="From"
            v-model="fields.jobSeeker.previousCompaniesWorkedFor[index].from"
            :error="
              errors.previousCompaniesWorkedFor &&
              errors.previousCompaniesWorkedFor[index] &&
              errors.previousCompaniesWorkedFor[index].from
            "
          >
          </yearly-date-picker>
          <b-form-checkbox
            class="ml-2 mb-3"
            :id="`${index}-current-checkbox`"
            :name="`${index}-current-checkbox`"
            v-model="fields.jobSeeker.previousCompaniesWorkedFor[index].current"
            >Still employed</b-form-checkbox
          >
          <yearly-date-picker
            placeholder="To"
            v-model="fields.jobSeeker.previousCompaniesWorkedFor[index].to"
            :error="
              errors.previousCompaniesWorkedFor &&
              errors.previousCompaniesWorkedFor[index] &&
              errors.previousCompaniesWorkedFor[index].to
            "
            :disabled="
              !!fields.jobSeeker.previousCompaniesWorkedFor[index].current
            "
          >
          </yearly-date-picker>
          <b-form-textarea
            v-model="
              fields.jobSeeker.previousCompaniesWorkedFor[index]
                .dailyResponsibilities
            "
            class="mb-4 daily-responsibilities-textarea"
            placeholder="Please describe your daily responsibilities"
          ></b-form-textarea>

          <text-input
            label="Reason For Leaving"
            v-model="
              fields.jobSeeker.previousCompaniesWorkedFor[index]
                .reasonForLeaving
            "
          />
        </b-col>
      </b-row>

      <base-button
        class="add-job-btn"
        icon="fas fa-plus"
        outlined
        @click="
          fields.jobSeeker.previousCompaniesWorkedFor =
            fields.jobSeeker.previousCompaniesWorkedFor.concat({
              name: '',
              from: '',
              to: '',
              current: false,
              position: '',
              reasonForLeaving: '',
            })
        "
        v-if="fields.jobSeeker.previousCompaniesWorkedFor.length <= 2"
      >
        Add Job</base-button
      >
    </div>

    <b-form-group
      label="Do you have any professional references that can verify your training or hands-on experience?"
      label-cols-sm="6"
      label-cols-lg="6"
      content-cols-sm
      content-cols-lg="6"
      v-slot="{ ariaDescribedby }"
      class="gfb-radio-container"
    >
      <b-form-radio-group
        v-model="fields.jobSeeker.hasReferences"
        :options="trueFalse"
        :aria-describedby="ariaDescribedby"
      ></b-form-radio-group>
    </b-form-group>

    <div v-if="this.editMode">
      <h3 class="profile-form-label">
        Profile photo <small>(Optional)</small>
      </h3>
      <small class="d-block"
        >Format 500x500 pixels, JPEG / PNG, max 10 MB</small
      >
      <div v-if="profilePhoto" class="photo-container p-3 mt-3 mb-3">
        <div class="photo">
          <signed-image :file="profilePhoto" alt="" class="w-100" />
        </div>
        <b-button
          @click="profilePhoto = null"
          pill
          variant="light"
          class="avatar-remove-button"
        >
          <i class="fa fa-times fa-lg" />
        </b-button>
      </div>
      <b-form-file
        class="mb-3"
        v-if="!profilePhoto"
        v-model="profilePhotoUpload"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        accept="image/jpeg, image/png"
      ></b-form-file>
      <form-error v-show="errors.profilePhotoUpload">{{
        errors.profilePhotoUpload
      }}</form-error>
    </div>

    <div class="form-group">
      <h3 class="profile-form-label resume-label">
        Resume <span class="optional-label">(Optional)</span>
      </h3>

      <div v-if="resume && editMode" class="old-resume-preview">
        <signed-file-download-button
          :file="resume"
          :btnLabel="'Open Resume'"
          :fileName="`${fields.jobSeeker.name}_resume.pdf`"
        />
        <b-button
          @click="resume = null"
          pill
          variant="light"
          title="Delete Resume"
          class="avatar-remove-button"
        >
          <i class="fa fa-trash"></i>
        </b-button>
      </div>

      <div v-if="resume && !editMode" class="resume-container">
        <b-button @click="resume = null" variant="light" class="rounded-lg mt-1"
          >Resume <i class="fa fa-times fa-lg ml-1"></i
        ></b-button>
      </div>
      <div v-if="!resume || editMode">
        <label for="uploadResume" class="file-input-wrapper">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
            >
              <path
                d="M53.0689 1.45462H25.5053C24.834 1.4507 24.1884 1.71209 23.7089 2.1819L9.45437 16.4364C8.98456 16.9159 8.72317 17.5615 8.72709 18.2328V60.3419C8.72709 61.017 8.99527 61.6644 9.47264 62.1418C9.95001 62.6192 10.5975 62.8874 11.2725 62.8874H53.0907C53.7658 62.8874 54.4133 62.6192 54.8906 62.1418C55.368 61.6644 55.6362 61.017 55.6362 60.3419V4.00008C55.6362 3.66396 55.5696 3.33117 55.4404 3.02091C55.3111 2.71065 55.1216 2.42907 54.8829 2.19241C54.6443 1.95576 54.3611 1.76872 54.0497 1.64209C53.7384 1.51546 53.405 1.45174 53.0689 1.45462ZM50.5235 57.8183H13.7962V20.8001H25.5053C26.1804 20.8001 26.8278 20.5319 27.3052 20.0545C27.7825 19.5772 28.0507 18.9297 28.0507 18.2546V6.54553H50.5235V57.8183Z"
                fill="#4879C9"
              />
              <path
                d="M24.7991 38.7273L29.6137 34.691V51.4183H34.7046V34.691L39.5191 38.7273L42.7919 34.8219L33.7955 27.3164C33.3371 26.9317 32.7577 26.7208 32.1591 26.7208C31.5606 26.7208 30.9812 26.9317 30.5228 27.3164L21.5337 34.8583L24.7991 38.7273Z"
                fill="#4879C9"
              />
            </svg>
          </div>
          <div class="drag-drop-info">
            Drag and drop or <a>Choose File</a> to upload
          </div>
          <div class="text-small">PDF, max 100MB</div>
        </label>
        <input
          type="file"
          style="display: none"
          id="uploadResume"
          @change="onResumeChange"
          accept="application/pdf"
          class="custom-file-input"
        />
      </div>

      <form-error v-show="errors.resumeUpload">{{
        errors.resumeUpload
      }}</form-error>
    </div>
    <b-form-group label="Briefly describe yourself">
      <b-form-textarea
        placeholder="Type here..."
        rows="8"
        max-rows="8"
        v-model="fields.description"
        maxlength="200"
        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus"
import validatePhotosMixin from "@/mixins/validatePhotosMixin"
import {
  deNullifyErrors,
  normalize,
  requiredError,
  validateEmail,
  validateUsPhoneNumber,
} from "@/utils/validations"
import YearlyDatePicker from "../../YearlyDatePicker.vue"
import options from "@/utils/options"

export default {
  mixins: [validatePhotosMixin],
  name: "candidate-profile-additional-information",
  components: {
    YearlyDatePicker,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: {},
      profilePhotoUpload: null,
      resumeUpload: null,
      deleteResume: false,
      resume: this.fields.jobSeeker.resume,
      profilePhoto: this.fields.profilePhoto,
      trueFalse: options.trueFalse,
    }
  },
  mounted() {
    EventBus.$on("validate-candidate-additional-info", () => {
      this.validate()
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("validate-candidate-additional-info")
  },
  methods: {
    onResumeChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.resumeUpload = files[0]
    },
    async validate() {
      const data = normalize(this.fields)
      const { jobSeeker } = data
      const { previousCompaniesWorkedFor, hasReferences, references } =
        jobSeeker ?? {}

      const previousCompanyErrors = !previousCompaniesWorkedFor
        ? null
        : previousCompaniesWorkedFor.reduce((errors, company) => {
            const { position, from, to, current } = company
            const companyError = {
              position: requiredError(position),
              from: requiredError(from),
              to: !current ? requiredError(to) : null,
            }
            return [
              ...errors,
              Object.keys(normalize(deNullifyErrors(companyError))).length > 0
                ? companyError
                : null,
            ]
          }, [])

      const referencesErrors =
        !hasReferences || !references
          ? null
          : references.reduce((errors, reference) => {
              const { name, email, phone, relationship } = reference
              const error = {
                name: requiredError(name),
                email: requiredError(
                  email,
                  !validateEmail(email)
                    ? "Please enter a valid email address"
                    : null
                ),
                phone: requiredError(
                  phone,
                  !validateUsPhoneNumber(phone)
                    ? "Please enter a valid US phone number"
                    : null
                ),
                relationship: requiredError(relationship),
              }
              return [
                ...errors,
                Object.keys(normalize(deNullifyErrors(error))).length > 0
                  ? error
                  : null,
              ]
            }, [])

      let errors = {
        previousCompaniesWorkedFor:
          previousCompanyErrors?.filter(Boolean).length > 0
            ? previousCompanyErrors
            : null,
        references:
          referencesErrors?.filter(Boolean).length > 0
            ? referencesErrors
            : null,
        profilePhotoUpload: this.profilePhotoUpload
          ? await this.validatePhotos([this.profilePhotoUpload])
          : null,
        resumeUpload:
          this.resumeUpload && this.resumeUpload.size > 100 * 1024 * 1024
            ? `Files must be under ${100 / 1024 / 1024} MB in size`
            : null,
      }
      this.errors = errors
      this.$emit(
        "change-photo-uploads",
        this.profilePhotoUpload,
        this.resumeUpload,
        this.profilePhoto,
        this.resume
      )
      this.$emit(
        "proceed-next",
        Object.keys(deNullifyErrors(errors)).length > 0 ? false : true
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.photo-container {
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  width: 200px;
  height: 200px;
}

.photo {
  border-radius: 22px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  & img {
    border-radius: 22px;
    object-fit: cover;
  }
}

.avatar-remove-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 30px;
  height: 30px;
}

.optional-label {
  color: var(--Gray-600, #525e77);
  font-size: 16px;
  font-weight: normal;
}

.old-resume-preview {
  display: flex;
  align-items: center;
  margin: 10px 0;

  .btn-medium {
    padding: 5px 15px;
    font-size: 15px;
  }
}

.resume-label {
  margin-bottom: 40px;
}

.employer-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 48, 73, 0.2);
  justify-content: space-between;
  margin-bottom: 25px;
  color: #fff !important;
  background: var(--gfb-blue-brand-500, #0095e2) !important;
  padding: 10px;

  &:first-of-type {
    margin-top: 25px;
  }

  h3.profile-form-label {
    color: #fff !important;
    margin-bottom: 0;
  }

  i {
    cursor: pointer;
  }
}

.custom-text-field .v-input__control > .v-input__slot fieldset {
  top: -5px !important;
}
</style>
